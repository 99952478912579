html * {
    font-family: 'Roboto Slab', serif;
  }

/* -----------------------------------common--------------------------------------   */

.md-btn {
    float: right;
    border: none;
    background: transparent;
    color: black;
}

.md-btn:hover {
    cursor: pointer;
}


/* ----------------------------ProductList component----------------------------------- */

.product-list-topdiv {
    max-width: 800px;
    margin: auto;
}

.product-list-table {
    width: 100%;
    margin: 10% auto 10% auto;
    border-collapse: collapse;
}

.product-list-table tr {
    width: 100%;
    }

.product-list-table thead tr {
    border-bottom: 1px solid black;
}

.product-list-table tbody tr {
    border-bottom: 1px solid #dad4d4;
}

.product-list-table tbody tr:hover {
    cursor: pointer;
}

.product-list-table tbody tr:last-child {
    border-bottom: 2px solid black;
}

.product-list-table td {
    max-width: 200px;
    padding: 2%;
}

.product-list-table img {
    border-radius: 5px;
    width: 100%;
}

.product-img {
    width: 40%;
}

.product-title {
    width: 40%;
}

.product-price {
    width: 20%;
}

.product-list-laser img {
    max-width: 600px;
}

.product-list-other-products img {
    max-width: 600px;
}

/* ------------------------------------------ProductDetail component----------------------------------------------- */

.product-detail-topdiv {
    width: 100%;
    margin: auto;
    text-align: center;
}

.product-detail-topdiv h1 {
    font-size: 5vw;
}

.product-detail-topdiv h2 {
    font-size: 4vw;
}

.product-detail-topdiv p {
    font-size: 20px;
}

.product-detail-img {
    display: inline-flex;
    /* width: 50%; */
}

.product-detail-img img {
    max-width: 500px;
    height: auto;
}

.product-detail-description {
    max-width: 600px;
    margin: 2%;
    display: inline-flex;
    vertical-align: top;
}

.product-detail-description p {
    display: inline-flex;
}


/* -----------------------------------Modal---------------------------------------- */

.modal {
    position: absolute;
    top: 1%;
    left: 1%;
    right: 1%;
    padding: 1%;
    border-radius: 20px;
    border: 1px solid black;
    background-color: white;
}

.modaloverlay {
    position: fixed;
    background-color: white;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.ReactModal__Overlay {
    overflow-y: auto;
  }